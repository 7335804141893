<template>
  <general-error v-if="errorType"></general-error>
  <router-view v-else/>
</template>

<script>

import { mapState } from 'vuex';
import GeneralError from '../../general-errors/GeneralError';

export default {
  name: 'SingleFactorAuthenticateToken',

  components: {
    GeneralError,
  },

  computed: {
    ...mapState('genericError', ['errorType']),
  },
};
</script>
