<template>
  <div class="enable-mobile padding-m">
    <riseup-button title="תנו לי אפליקציה!" @click="save()" :loading="loading" />
    <input-copy :input-content="customerId.toString()" input-direction="ltr" size="slim" class="margin-top-l"></input-copy>
    <div v-if="showResults">
      <div v-if="results.length > 0">
        <div class="margin-top-l margin-bottom-s" style="text-align: center">משהו נשבר 💔</div>
        <div v-for="result in results" :key="result.step" class="margin-top-l flex-column" style="text-align: end">
          {{ result.step }}: {{ result.message }}
        </div>
      </div>
      <div v-else class="margin-top-l" style="text-align: center">
        <div>הכל טוב!</div>
        <div class="margin-top-s">הפעלת האפליקציה הושלמה בהצלחה</div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import enableMobileApi from '@/api/EnableMobileApi';
import { mapState } from 'vuex';

export default {
  name: 'EnableMobile',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    InputCopy: BaseUI.InputCopy,
  },
  computed: {
    ...mapState('session', ['customerId']),
  },
  data() {
    return {
      results: [],
      loading: false,
      showResults: false,
    };
  },
  methods: {
    async save() {
      this.showResults = false;
      this.loading = true;
      try {
        this.results = (await enableMobileApi.enableMobile()).filter(result => result.status === 'error');
      } finally {
        this.loading = false;
        this.showResults = true;
      }
    },
  },
};

</script>
