import { clientWithoutErrorHandling } from '../http';

async function enableMobile() {
  const response = await clientWithoutErrorHandling.post('/api/mobile/enable');
  return response.data;
}

export default {
  enableMobile,
};
