<template>
  <div/>
</template>

<script>

import Segment from '@/Segment';

export default {
  name: 'SignupPartners',
  props: {
    icrc: {
      type: String,
      required: true,
    },
    utm_params: {
      type: Object,
      required: true,
    },
  },
  async created() {
    this.$cookies.set('riseup.icrc', this.icrc);
    this.$cookies.set('riseup.utm_params', this.utm_params);
    Segment.trackUserGot('SignUpPartnersEntered', { icrc: this.icrc, utm_params: this.utm_params });
    window.location.href = 'https://www.mcc.co.il/site/pg/mcc_item_new,329116';
  },
};
</script>

<style lang="scss" scoped>
</style>
