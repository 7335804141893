<template>
    <div class="reset-password-error">
      <img :src="require('@/assets/generic-faliure.png')">
      <div class="ri-large-headline ri-alte margin-top-ml ">נתקלנו בבעיה באימות, אנחנו מטפלים בזה!</div>
      <span class="ri-large-body">נעדכן אותך בוואטסאפ ברגע שהבעיה תיפתר.</span>
    </div>
</template>

<script>

import Segment from '@/Segment';

export default {
  name: '2faResetPasswordError',
  async created() {
    Segment.trackUserGot('2faResetPasswordError');
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/spacings";

.reset-password-error {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 30px;

  img {
    width: 100%;
  }
}
</style>
